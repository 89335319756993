/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Image, Text, Title, Button, SeparateLine, SeparateLineWrap } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Úvodní stránka"} description={"vstup na stranky"}>
        <Column className="pt--10" name={"oddelovaci-cara"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
          </ColumnWrap>

        </Column>


        <Column className="css-1olfcl5" name={"uvod-vstup"} css={css`
      
    background-image: linear-gradient(rgba(255,255,255,0.35), rgba(255,255,255,0.35)), url(https://cdn.swbpg.com/t/17440/dddbee03aa9d4a3a8b9b7b38f3b581f7_r=90__s=350x_.JPG);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(255,255,255,0.35), rgba(255,255,255,0.35)), url(https://cdn.swbpg.com/t/17440/dddbee03aa9d4a3a8b9b7b38f3b581f7_r=90__s=660x_.JPG);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(255,255,255,0.35), rgba(255,255,255,0.35)), url(https://cdn.swbpg.com/t/17440/dddbee03aa9d4a3a8b9b7b38f3b581f7_r=90__s=860x_.JPG);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(255,255,255,0.35), rgba(255,255,255,0.35)), url(https://cdn.swbpg.com/t/17440/dddbee03aa9d4a3a8b9b7b38f3b581f7_r=90__s=1400x_.JPG);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(255,255,255,0.35), rgba(255,255,255,0.35)), url(https://cdn.swbpg.com/t/17440/dddbee03aa9d4a3a8b9b7b38f3b581f7_r=90__s=2000x_.JPG);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(255,255,255,0.35), rgba(255,255,255,0.35)), url(https://cdn.swbpg.com/t/17440/dddbee03aa9d4a3a8b9b7b38f3b581f7_r=90__s=660x_.JPG);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(255,255,255,0.35), rgba(255,255,255,0.35)), url(https://cdn.swbpg.com/t/17440/dddbee03aa9d4a3a8b9b7b38f3b581f7_r=90__s=1400x_.JPG);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(255,255,255,0.35), rgba(255,255,255,0.35)), url(https://cdn.swbpg.com/t/17440/dddbee03aa9d4a3a8b9b7b38f3b581f7_r=90__s=3000x_.JPG);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center mb--60 mt--16">
            
            <ColumnWrapper style={{"maxWidth":742}}>
              
              <Image className="--shape2 mb--0 mt--0 pb--0 pt--0" src={"https://cdn.swbpg.com/t/17440/612295c276094931bd4182d57381fb73_s=660x_.png"} svg={false} ratio={null} sizes="(max-width: 1439px) 100vw, 1440px" style={{"maxWidth":546}} srcSet={"https://cdn.swbpg.com/t/17440/612295c276094931bd4182d57381fb73_s=350x_.png 350w, https://cdn.swbpg.com/t/17440/612295c276094931bd4182d57381fb73_s=660x_.png 660w, https://cdn.swbpg.com/t/17440/612295c276094931bd4182d57381fb73_s=860x_.png 860w"} position={null} svgColor={"rgba(0,0,0,1)"}>
              </Image>

              <Text className="text-box swpf--uppercase ls--20 mt--0" style={{"color":"rgb(0, 0, 0)","maxWidth":849}} content={"tradice poctivého řemesla"}>
              </Text>

              <Title className="title-box fs--48 swpf--uppercase mt--02" style={{"maxWidth":861}} content={"kamna, sporáky, KRBY"}>
              </Title>

              <Text className="text-box w--500 mt--20" style={{"maxWidth":900}} content={"<span style=\"color: rgb(0, 0, 0);\">Chcete cítit opravdové teplo rodinného krbu? Dávate přednost tradici a kvalitě? <br>Pak jste u nás správně. Neváhejte a vstupte!</span>"}>
              </Text>

              <Button className="btn-box btn-box--hvr2 btn-box--style2 btn-box--filling2 w--600 swpf--uppercase mt--25" href={"/nabizime"} target={null} content={"tudy!"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine name={"uvod-oddelovac-1"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column name={"uvod-paticka"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <Button className="btn-box btn-box--pbtn2 btn-box--filling2 fs--11" href={"/zasady-ochrany-osobnich-udaju"} content={"ZÁSADY OCHRANY OSOBNÍCH ÚDAJŮ"}>
              </Button>

              <Text className="text-box ff--3 mt--06" style={{"color":"rgb(0, 0, 0)"}} content={"Přečtěte si podrobné informace o našich Zásadách ochrany osobních údajů před dalším užíváním těchto stránek.\n<br>Veškeré zde zveřejněné informace a fotografie jsou majetkem Kamnářství na Rozkoši. Jejich šíření je bez souhlasu majitele zakázáno! Děkujeme, že respektujete autorská práva.<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}